*,
body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif !important;
}

html,
body,
#root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

svg,
path {
  /* max-width: 100%; */
  max-height: 100%;
}
